<template>
  <div id="apps">
   <div class='wxtuihuo clear'>
    <div class="head1 clear">
      <label>申请原因</label>
      <span>{{conetn}}</span>
      <img src="../assets/jt2.png" @click="shows()"/>
      <div class="iop" v-if="showy">
        <span v-for="(x,i) in ddf" @click="showie(x)">{{x}}</span>
      </div>
    </div>
    <div class="jine clear">
      <p>退款金额</p>
      <span>¥14.9</span>
    </div>
    <div class="sqy clear">
      <div class="yuan clear"><span>申请说明</span><span>您还可以输入170个字</span></div>
      <textarea placeholder="必填，请你详细填写申请原因"></textarea>
      <div class="isn"><input type="file" accept="image/*" class="filed">
      <img src="../assets/sctp.png"/></div>
    </div>
    <div class="tel clear"><label>联系电话</label><span>1524035199</span></div>
    <button class="tksq">提交申请</button>
   </div>
  </div>
</template>
<script>
export default {
  name: 'app',
  components:{
  },
   data () {
      return {
        showy:false,
        ddf:['多拍','错拍','不喜欢','其他'],
        conetn:'点击选择申请原因'
      }
  },
  methods:{
    shows(){
      this.showy=true;
    },
    showie(x){
      this.conetn=x;
      this.showy=false
    }
  }
}
</script>
<style>
  .clear{clear:both;overflow: hidden;}
  *{margin:0;padding:0;}
  li{list-style-type: none;}
  body{background-color: rgba(248, 248, 248, 100);height: 100vh;}
.wxtuihuo .tksq{background:#DE3A2E;font-size:13px;width:92%;margin:3% auto;border-radius:5px;display: block;text-align: center;padding:3% 0;border:none;color:#fff;}
.wxtuihuo .tel{background:#fff;padding:3% 0;font-size:13px;margin-bottom:15%;margin-top:3%;}
.wxtuihuo .tel label{display: inline-block;float: left;color:#828282;margin:0 3%;}
.wxtuihuo .tel span{display: inline-block;float: left;color:#494949;}
.wxtuihuo .head1{background:#fff;font-size:12px;padding-top: 20%;position: relative;}
.wxtuihuo .head1 label{display: inline-block;float: left;color:#686868;margin:3%;}
.wxtuihuo .head1 span{display: inline-block;float: left;color:#CECECE;margin-top:3%;}
.wxtuihuo .head1 img{width:16px;float: right;margin-right:3%;margin-top:3%;}
.wxtuihuo .iop{float: right;position: absolute;right: 3%;bottom:3%;background: #fff;}
.wxtuihuo .head1 .iop span{border-bottom:dashed 1px #CECECE;line-height:20px;width:100%;text-align:center;display: block;font-size:12px;color:#686868;clear:both;overflow: hidden;}
.wxtuihuo .jine{background: #fff;margin:3% 0;}
.wxtuihuo .jine p,.jine span{display: block;font-size:13px;color:#686868;margin:3%;}
.wxtuihuo .jine span{font-size:18px;color:#000;font-weight:600;}
.wxtuihuo .sqy{background: #fff;padding:4% 0;}
.wxtuihuo .sqy .yuan span{display: inline-block;float: left;color:#686868;font-size:13px;}
.wxtuihuo .sqy .yuan span:nth-child(1){float: left;margin-left:3%;}
.wxtuihuo .sqy .yuan span:nth-child(2){color: #CECECE;font-size:12px;float: right;margin-right:3%;}
.wxtuihuo .sqy textarea{width:94%;margin:3% auto;height:50px;border:none;resize: none;display: block;}
        textarea:-ms-input-placeholder {
            color: #CECECE;
        }
        textarea::-webkit-input-placeholder {
            color: #CECECE;
        }
.filed{color:#fff;background: #fff;opacity: 0;position: absolute;top:3%;left:0;z-index: 44;height: 100%;}
.isn{width: 25%;display: block;margin:3% 0 0% 3% ;position: relative;padding-bottom:25%;}
.isn img{position: absolute;top:0;left: 0;width: 100%;display: block;z-index: 2;}
  </style>
